import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  styles,
  SubHeading,
  Heading,
  Padding,
  PinkDelimiter,
  Text,
} from "../../common/styles"
import { graphql, StaticQuery } from "gatsby"
import _ from "lodash"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import { media } from "../../common/mediaQueries"
import Img from "gatsby-image/withIEPolyfill"

const Presseartikel = () => {
  return (
    <Layout>
      <SEO
        title="Presseartikel"
        keywords={["Presseartikel", "Patricia on Ice"]}
      />
      <Wrapper>
        <Padding value="2vw" axis="y" />
        <Heading color={styles.colors.blue.dark}>Presseartikel</Heading>
        <Padding value="2vw" axis="y" />
        <PinkDelimiter />
        <Padding value="2vw" axis="y" />
      </Wrapper>
      <StaticQuery
        query={graphql`
          {
            allWordpressPost(
              filter: {
                categories: { elemMatch: { name: { eq: "Presseartikel" } } }
              }
            ) {
              edges {
                node {
                  id
                  title
                  content
                  date(locale: "de")
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return _.map(
            _.reverse(_.sortBy(data.allWordpressPost.edges, ["node.date"])),
            edge => {
              const currentPost = edge.node
              return (
                <div>
                  {_.get(
                    currentPost,
                    "featured_media.localFile.childImageSharp.fluid"
                  ) && (
                    <ImageWrapper
                      children={
                        <Img
                          alt="Patricia On Ice - Foto"
                          fluid={_.get(
                            currentPost,
                            "featured_media.localFile.childImageSharp.fluid"
                          )}
                        />
                      }
                    />
                  )}
                  <PostWrapper>
                    <Padding value="2vw" axis="y" />
                    <SubHeading color={styles.colors.blue.dark}>
                      {currentPost.title}
                    </SubHeading>
                    <Padding value="1vw" axis="y" />
                    <PinkDelimiter />
                    <Padding value="2vw" axis="y" />
                    <Text>{parse(currentPost.content)}</Text>
                  </PostWrapper>
                  <Padding value="4vw" axis="y" />
                </div>
              )
            }
          )
        }}
      />
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

const PostWrapper = styled.div`
  display: flex;
  flex-basis: auto;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  width: 80vw;
  height: 100%;
  margin: 0 auto;
  .wp-block-embed-youtube {
    width: 80vw;
    * {
      width: 60vw;
      height: 33.75vw;
      margin: 0 auto;
    }
  }
  ${media.mobile} {
    .wp-block-embed-youtube {
      width: 80vw;
      * {
        width: 80vw;
        height: 45vw;
        margin: 0 auto;
      }
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  * {
    flex: 0 0 30vw;
    object-position: top !important;
  }
  ${media.mobile} {
    * {
      flex: 0 0 50vw;
    }
  }
`

export default Presseartikel
